import React from 'react'
const FlexibilityIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 300 300"
		fill="currentColor"
		{...props}
	>
		<title>Icon of a knob turned up to 80% of its maximum range</title>
		<path
			d="M150,279.31a129.11,129.11,0,1,0-129.16-129v0A129.11,129.11,0,0,0,150,279.31ZM150,300A150,150,0,1,1,300,150,150,150,0,0,1,150,300Z"
			fill="#a9bbcd"
		/>
		<path
			d="M159.34,207.59a44.46,44.46,0,1,1,44.51-44.4,2.26,2.26,0,0,1,0,.26,44.3,44.3,0,0,1-44.45,44.15Zm-19.19-69.23a8.46,8.46,0,0,0-1.53.91c-1.24,1.16-2.4,2.38-3.62,3.57a2,2,0,0,0-.43,2.85,2,2,0,0,0,.43.43l12.93,12.93a2.12,2.12,0,0,0,3,.48,1.83,1.83,0,0,0,.48-.48l3.31-3.33a2.11,2.11,0,0,0,.49-2.93,2.07,2.07,0,0,0-.49-.49c-4.27-4.27-8.53-8.56-12.93-12.93A9.18,9.18,0,0,0,140.15,138.36Z"
			fill="#5888a7"
		/>
		<path
			d="M161.2,90.65v12.67c0,2.38-.39,2.59-2.77,2.77a57,57,0,0,0-8.71,113.22c19.47,3.23,36.2-2.59,50.43-16.22,1.83-1.75,2.58-2,3.95-.72a2.18,2.18,0,0,1,.4,3,2.46,2.46,0,0,1-.4.39A72.91,72.91,0,0,1,186,220a73.77,73.77,0,0,1-99.31-25.76,68.3,68.3,0,0,1-10.58-32.53C74.28,128.09,89,103.16,117.65,86.15a79.53,79.53,0,0,1,40.55-11c2.58,0,3,.26,3,2.87Z"
			fill="#5888a7"
		/>
	</svg>
)
export default FlexibilityIcon
