import React from 'react'
const ControlIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 300 300"
		fill="currentColor"
		{...props}
	>
		<title>Icon of two gears, one big and one little, interlocking</title>
		<path
			d="M150,279.31a129.11,129.11,0,1,0-129.16-129v0A129.11,129.11,0,0,0,150,279.31ZM150,300A150,150,0,1,1,300,150,150,150,0,0,1,150,300Z"
			fill="#a9bbcd"
		/>
		<path
			d="M159.18,103.53l-4.73,8a7.21,7.21,0,0,0,4.32,11.25c11.58,3.34,9.93,1.6,9.93,13.71,0,7.76,0,7.76-7.76,9.57-1,.2-1.86.51-2.82.75-5.74,1.39-7,4.52-4.16,9.85,5.32,9.93,6.38,7.76-2.59,16.29-6,5.67-6,5.67-12.93,1.3-1-.6-1.91-1.24-2.9-1.79a5.35,5.35,0,0,0-8.61,3.24c-3.54,11.25-3.54,11-15.18,11.22-4.91,0-7.76-1.35-8.15-6.52a29.2,29.2,0,0,0-1-4.21c-1.34-5.62-5.35-7.43-10.34-4.63-10.58,5.82-8.2,6.57-17-2.59-5.43-5.71-5.43-5.71-1.27-12.28,1-1.66,2.87-3.31,2.85-4.94a17,17,0,0,0-2.41-6.34c-.13-.28-.9-.21-1.34-.41-3.47-1.48-9-2.23-9.91-4.63-1.6-4.42-.59-9.83-.21-14.8,0-1.08,1.92-2.37,3.24-3a33.3,33.3,0,0,1,6.05-1.68c5.92-1.42,7.76-5,4.78-10.16-5.58-10-5.58-8.23,2.43-16,6.06-5.87,6.06-5.87,13.3-1.4,6.59,4.09,10.08,2.74,12.2-4.7,2.36-8.33,2.36-8.33,11.25-8h1.45c8.41.26,8.41.26,10.35,8.54,1.68,7.34,4.47,8.53,11.14,4.78,7.48-4.19,7.5-4.21,13.58,2.23C154.89,98.56,156.91,100.94,159.18,103.53Zm-63.65,30a19.42,19.42,0,0,0,18.57,20.24c.48,0,1,0,1.45,0a19.78,19.78,0,0,0,20.2-19.36c0-.29,0-.57,0-.86a19.5,19.5,0,0,0-19.08-19.92h-.83a19.23,19.23,0,0,0-20.29,18.09C95.52,132.35,95.51,133,95.53,133.55Z"
			fill="#5888a7"
		/>
		<path
			d="M195.49,219.88a45.42,45.42,0,0,1-3.8-3.8A10.35,10.35,0,0,1,190.4,213c-2.15-5.17-4.74-5.89-9.49-2.58-2.15,1.47-5.33,3.93-6.94,3.26a25.55,25.55,0,0,1-9.33-7.35c-.91-1.08.54-4.47,1.42-6.59,2.77-6.75,2.43-7.35-4.78-8.77-2.2-.44-5.69-1.16-6.13-2.59a30,30,0,0,1-1.22-11.84c0-1.29,3.41-2.59,5.41-3.34,6.1-2.58,6.72-4.81,2.94-10-1.34-1.86-3.46-4.89-2.81-6.13a35.22,35.22,0,0,1,7.75-9.67c.83-.73,3.86.64,5.75,1.42,6.07,2.58,9.15,1,9.36-5.38.18-5.17,2.58-7,7.5-7.19,7.76-.34,7.76-.54,11,6.75,1.66,3.78,4.06,4.37,7.55,2.15,2.36-1.48,5.49-4.19,7.32-3.57a24.07,24.07,0,0,1,9.26,6.93c.88.93.16,3.88-.54,5.64-2.82,7-1.43,9.54,6,10.57,3,.44,6,.42,6.52,4.87,1.16,10.57,1.45,11-4.5,12.75s-7.76,5.5-3.1,10.83c4.11,4.63,3.57,4.71-1.79,12.18-3,4.12-5.77,3.47-9.46,1.74-6.18-2.87-7.76-2.23-9,2.92C207.08,219.39,207.08,219.39,195.49,219.88Zm0-26.17a15.94,15.94,0,1,0-15.82-16.28c0,.06,0,.11,0,.16A15.72,15.72,0,0,0,195,193.7h.49Z"
			fill="#5888a7"
		/>
	</svg>
)
export default ControlIcon
