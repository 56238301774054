import React from 'react'
const SelfCleaningIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 300 300"
		fill="currentColor"
		{...props}
	>
		<title>Icon of 3 curving arrows arranged in a circle</title>
		<path
			d="M150,279.31a129.11,129.11,0,1,0-129.16-129v0A129.11,129.11,0,0,0,150,279.31ZM150,300A150,150,0,1,1,300,150,150,150,0,0,1,150,300Z"
			fill="#a9bbcd"
		/>
		<path
			d="M194,228.21c-2.59-3.05-5.18-5.67-7.48-8.46a3.51,3.51,0,0,0-4.85-1.09l-.19.13c-27,12-52.48,8.64-76.79-8.48l11.2-14.25c17.25,12.36,35.54,15.36,56,7.4l-9.26-10.66,46.19-9.41Z"
			fill="#5888a7"
		/>
		<path
			d="M222.93,166.73l-16.81-2.92c2.59-20.17-3-37.24-18.36-51.72l-5.17,13.78L151.91,90.23l46.22-8.76a87,87,0,0,1-2.79,8.5c-1.35,3-.73,4.71,1.88,6.88C218.33,114.44,227.15,137,224,164.3a5.71,5.71,0,0,1-.29,1.35C223.6,165.93,223.34,166.14,222.93,166.73Z"
			fill="#5888a7"
		/>
		<path
			d="M99.18,143.82l12.54-2.35L96.78,186.26,66,151.27c.62-.55.85-.88,1.06-.88,9.49,0,13.89-3.91,15.88-14.2,4.16-21.72,17.74-37.37,37.22-47.79,1.86-1,3.85-1.76,6.07-2.8l6.6,16.56C115,110.51,103.45,123.75,99.18,143.82Z"
			fill="#5888a7"
		/>
	</svg>
)
export default SelfCleaningIcon
