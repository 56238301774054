import React from 'react'
const ReliableIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 300 300"
		fill="currentColor"
		{...props}
	>
		<title>Icon of a tick</title>
		<path
			d="M214.32,123.62a12.17,12.17,0,0,1-4.09,9l-44,43.81L151.73,191c-5.71,5.74-12.59,5.79-18.28,0q-16.78-16.81-33.62-33.62a11.75,11.75,0,0,1-2.43-14,11.88,11.88,0,0,1,11.94-6.44,13.2,13.2,0,0,1,7.92,4.25c7.94,8,16,15.93,23.87,24,1.19,1.21,1.76,1,2.82,0q24.83-24.9,49.71-49.76A11.87,11.87,0,0,1,213.93,121a8.13,8.13,0,0,1,.21,1A5,5,0,0,1,214.32,123.62Z"
			fill="#5888a7"
		/>
		<path
			d="M150,279.31a129.11,129.11,0,1,0-129.16-129v0A129.11,129.11,0,0,0,150,279.31ZM150,300A150,150,0,1,1,300,150,150,150,0,0,1,150,300Z"
			fill="#a9bbcd"
		/>
	</svg>
)
export default ReliableIcon
