import React from 'react'
const SafetyMeasuresIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 300 300"
		fill="currentColor"
		{...props}
	>
		<title>Icon of a thermometer thats measuring at half</title>
		<path
			d="M150,279.31a129.11,129.11,0,1,0-129.16-129v0A129.11,129.11,0,0,0,150,279.31ZM150,300A150,150,0,1,1,300,150,150,150,0,0,1,150,300Z"
			fill="#a9bbcd"
		/>
		<path
			d="M125.38,170.3c.85.47,1.65,1,2.59,1.48h0C127,171.28,126.23,170.77,125.38,170.3Z"
			fill="#5888a7"
		/>
		<path
			d="M143.82,161.53c-1.89-1.47-3.83-2.89-5.74-4.37h0C140,158.64,141.93,160.06,143.82,161.53Z"
			fill="#5888a7"
		/>
		<path
			d="M134.61,155.15c1.19.59,2.3,1.34,3.44,2h0C136.91,156.49,135.8,155.74,134.61,155.15Z"
			fill="#5888a7"
		/>
		<path
			d="M132.21,175.14a15,15,0,0,1-4.24-3.31h0A15,15,0,0,0,132.21,175.14Z"
			fill="#5888a7"
		/>
		<path
			d="M147.78,142.53a1.41,1.41,0,0,0,.41,0h0A1.41,1.41,0,0,1,147.78,142.53Z"
			fill="#5888a7"
		/>
		<path
			d="M154,146.92c-1.92-1.47-3.88-2.89-5.79-4.34h0C150.13,144,152.09,145.45,154,146.92Z"
			fill="#5888a7"
		/>
		<path
			d="M124.81,176.15a16.06,16.06,0,0,0-4-3.18A16.06,16.06,0,0,1,124.81,176.15Z"
			fill="#5888a7"
		/>
		<path
			d="M124.84,176.15c2,1.29,4,2.58,6,3.93-2-1.35-4-2.59-6-3.93Z"
			fill="#5888a7"
		/>
		<path
			d="M207.52,70.91c-8.9-6.28-20.43-3.88-27.16,5.75q-33.62,47.94-67.08,95.94a8.21,8.21,0,0,1-5.77,3.81,30.13,30.13,0,0,0,5.71,59.74A29.81,29.81,0,0,0,143,201.52a9.56,9.56,0,0,1,2.12-7.53q33.36-47.56,66.59-95.2C218.43,89.12,216.65,77.35,207.52,70.91Zm1.16,23.77c-11,16-22.14,31.76-33.21,47.64-11.87,17-23.66,34-35.63,51a8.52,8.52,0,0,0-1.66,7.5A25.42,25.42,0,0,1,118.78,231l-1.13.22A25.87,25.87,0,0,1,88,209.9l0-.13a25.09,25.09,0,0,1,20.69-28.71c5.9-.88,7.76-5.17,10.63-8.92l1.5.8a16.21,16.21,0,0,1,4.06,3.21l3.08-4.43c-.86-.49-1.66-1-2.59-1.47l-.9-.44c-2.38-1.11-2.2-2.46-.68-4.19.88-1,1.61-2.2,2.38-3.28,3.65-5.18,3.65-5.18,8.8-.81h0l3.07-4.42c-1.13-.67-2.25-1.42-3.44-2-2-.93-2.27-2.12-.85-3.8.85-1.06,1.58-2.22,2.35-3.33,3.86-5.43,3.86-5.43,9.16-1l3-4.39a1.41,1.41,0,0,1-.41,0c-5.54-3.31-5.54-3.31-1.94-8.36l.54-.75c2.12-3,3-4.34,4.4-4.16a3,3,0,0,1,1,.36,9.59,9.59,0,0,1,1.21.78c.65.49,1.45,1.11,2.38,1.88l3-4.31h0a3.23,3.23,0,0,1-.44,0c-5.32-3.55-5.32-3.55-1.81-8.51a16.35,16.35,0,0,0,1.27-1.92c1.66-3.51,3.62-4.24,6.75-1.32a84.65,84.65,0,0,0,7.45,5.18c1.5,1.11,2.82,1.24,3.88-.37s0-2.58-1.11-3.44c-3.11-2.22-6.18-4.47-9.39-6.51-1.66-1.06-1.76-1.76-.67-3.47,5.79-9,5.63-9,14.3-3.15,2,1.32,4.39,5.32,6.77,2s-2.58-3.88-4-5.76a5.16,5.16,0,0,0-1.11-.75c-6.93-4.84-6.93-4.76-2.18-12,1.89-2.85,3.39-3.73,6.16-1.17A38.26,38.26,0,0,0,190.14,91c1.65,1,3.57,3.6,5.45,1.06s-.9-3.62-2.58-4.81a79.28,79.28,0,0,0-7.11-5c-2.59-1.42-2-2.74-.62-4.58a14.45,14.45,0,0,1,23.27,16.94Z"
			fill="#5888a7"
		/>
		<path d="M124.81,176.15l-.34.49.37-.49Z" fill="#5888a7" />
		<path d="M158.35,128h0l.21-.31Z" fill="#5888a7" />
		<path d="M135,161.59h0L134,163Z" fill="#5888a7" />
		<path
			d="M155.35,132.18c-.93-.77-1.73-1.4-2.38-1.89C153.62,130.78,154.42,131.41,155.35,132.18Z"
			fill="#5888a7"
		/>
		<path
			d="M169.76,138.65c5.17-7.22,5-7.22-2.07-12.24-5.17-3.72-5.38-3.77-9.13,1.25l-.21.31c1.94,1.44,3.91,2.84,5.82,4.34,1.14.91,2.05,2,1,3.52a2.35,2.35,0,0,1-3.6.49l-6.15-4h0l-7.27,10.17h0c1.91,1.44,3.88,2.87,5.79,4.34,1.19.93,1.81,2,.83,3.54s-2.25,1.25-3.52.42l-6.13-3.93h0L138,157.24h0c1.91,1.48,3.85,2.9,5.74,4.37,1.19.93,1.94,2,.82,3.47a2.33,2.33,0,0,1-3.2.77,2.48,2.48,0,0,1-.36-.28c-2-1.32-4.06-2.59-6.11-3.91l-1,1.43-6.1,8.76h0a15,15,0,0,0,4.24,3.31,12.44,12.44,0,0,1,1.42,1.09c1.06.93,1.86,2.09.8,3.52s-2.25,1.24-3.57.39c-2-1.35-4-2.59-6-3.94l-.37.5c-1.24,2-2.79,3.82-4,5.87a8.19,8.19,0,0,1-7.76,4.55,18.1,18.1,0,0,0-17.38,13.63,19.21,19.21,0,0,0,7.89,20.69,18.7,18.7,0,0,0,22.14-1.09A18.36,18.36,0,0,0,131,199a5.7,5.7,0,0,1,.86-6Q150.86,165.91,169.76,138.65Z"
			fill="#5888a7"
		/>
	</svg>
)
export default SafetyMeasuresIcon
