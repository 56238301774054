import React from 'react'
const WarrantyIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 207 184"
		fill="currentColor"
		{...props}
	>
		<title>Market leading warranty</title>
		<path d="M17.2,95.58l6.4,17.56h0l.72-12.53c.16-2.64,1.12-3.75,2.7-4s2.86.44,3.9,2.88L35.83,111h0l.07-18.66,4.73-.84-.78,21.37c-.09,2.52-1,3.68-2.76,4S34,116.39,33,114l-5-11.61-.69,12.62c-.13,2.47-1.17,3.69-2.91,4s-3-.46-4-2.79l-8-19.82Z" />
		<path d="M45,115.15l3.41-21.92c.39-2.46,1.5-3.67,3.32-4s3.29.51,4.46,2.63l10.58,19.45-5.11.9-2.86-5.56-8.09,1.43L50,114.27Zm12-12-4.64-9.08-1.22,10.11Z" />
		<path d="M74.69,109.92l-4.81.84L65.77,87.43a45.56,45.56,0,0,1,7.61-2c5.24-.93,9.72-.64,10.85,5.78.74,4.19-.68,6.48-3.32,7.82l7.1,8.55-5.55,1L76,100.45c-1,.17-2.08.31-2.93.41ZM72.42,97a24.32,24.32,0,0,0,3-.4c3-.52,4.62-1.6,4.09-4.61-.47-2.63-1.83-3.47-5.3-2.86a22.24,22.24,0,0,0-3,.74Z" />
		<path d="M96.55,106.06l-4.81.85L87.63,83.58a46.48,46.48,0,0,1,7.61-2c5.24-.92,9.71-.64,10.85,5.78.74,4.2-.68,6.49-3.32,7.82l7.1,8.55-5.55,1-6.45-8.1c-1,.17-2.07.32-2.92.41ZM94.28,93.17a26.45,26.45,0,0,0,3-.39c3-.53,4.62-1.6,4.08-4.62-.46-2.63-1.83-3.47-5.29-2.86A25.72,25.72,0,0,0,93,86Z" />
		<path d="M112.62,103.23,116,81.31c.39-2.46,1.5-3.68,3.32-4s3.29.5,4.46,2.63l10.58,19.45-5.11.9-2.85-5.56-8.1,1.43-.74,6.19Zm12-12L120,82.16l-1.21,10.11Z" />
		<path d="M150.93,90l-3.07-17.41,4.33-.77,3.59,20.37c.44,2.53-.42,3.81-2.28,4.13s-3-.26-4.65-2.4L138.75,80.6l3.08,17.48-4.33.76-3.59-20.38c-.44-2.52.45-3.79,2.28-4.12,1.65-.29,3,.32,4.63,2.4Z" />
		<path d="M172.88,72.14l-5.84,1L170.54,93l-4.84.86L162.2,74l-5.8,1-.69-3.9,16.48-2.9Z" />
		<path d="M191.18,89.38l-4.82.85-1.62-9.15L174.53,67.83l5.19-.91,6.63,9,3.3-10.73,5-.88-5.09,15.94Z" />
		<path d="M103.58,168.27a75.55,75.55,0,0,1-69.62-46,1.49,1.49,0,0,1,2.74-1.16A72.61,72.61,0,0,0,176,96.56a1.49,1.49,0,0,1,1.56-1.41,1.47,1.47,0,0,1,1.4,1.56,75.56,75.56,0,0,1-62.35,70.42A74.69,74.69,0,0,1,103.58,168.27Z" />
		<path d="M29.54,90.33h-.08a1.49,1.49,0,0,1-1.41-1.56A75.59,75.59,0,0,1,173.18,63.36a1.49,1.49,0,1,1-2.74,1.15A72.62,72.62,0,0,0,31,88.92,1.48,1.48,0,0,1,29.54,90.33Z" />
		<path d="M103.61,142.46a50,50,0,0,1-43.19-24.84A1.47,1.47,0,0,1,63,116.14,46.91,46.91,0,0,0,149.69,101a1.47,1.47,0,0,1,2.89.53,49.78,49.78,0,0,1-49,40.92Z" />
		<path d="M55.89,85.89l-.25,0a1.47,1.47,0,0,1-1.2-1.7,49.84,49.84,0,0,1,92.33-16.33,1.47,1.47,0,1,1-2.55,1.46A46.9,46.9,0,0,0,57.34,84.67,1.48,1.48,0,0,1,55.89,85.89Z" />
		<path d="M54.74,58.38l-4.42-6.93c-.76-1.19-.82-2.27-.29-3,.8-1.12,1.61-1.18,3-.28l11.11,7.24-1.75,2.45-9.17-6.12h0l4.52,6.64a1.74,1.74,0,0,1-2,2.79L48,59.12h0l8.71,6.71L55,68.19,44.57,60c-1.24-1-1.49-1.83-.7-2.93.52-.72,1.46-1,2.87-.68Z" />
		<path d="M67.22,52.62,63.42,39c-.42-1.53-.1-2.53.88-3.19s2-.58,3.28.35l11.28,8.5-2.74,1.87L73,44.05l-4.32,3,1.2,3.79ZM71,42.48l-5.09-4,2,6.19Z" />
		<path d="M86,42.06,83,43,78.67,28.49a29,29,0,0,1,4.67-1.84c3.25-1,6.1-1.13,7.3,2.85.78,2.6,0,4.15-1.52,5.19l5.13,4.89-3.44,1L86.13,36c-.6.19-1.3.36-1.82.48Zm-2.4-8a17.29,17.29,0,0,0,1.85-.47c1.85-.56,2.8-1.36,2.24-3.23-.49-1.63-1.42-2.06-3.57-1.41a13.27,13.27,0,0,0-1.84.69Z" />
		<path d="M102.32,30.83l5.19-7.07,3.63,0-5.6,7.3,5.52,8.07-3.79,0-5-7.6-.08,7.55L99.1,39l.17-15.37,3.13,0Z" />
		<path d="M128.18,30.57l-4.06-1.24c-1.76-.53-2.18-.26-2.68,1.36l-.5,1.67,5.85,1.79-.73,2.37-5.85-1.78-.58,1.91c-.5,1.63-.31,2.09,1.45,2.63l4.07,1.24-.71,2.32c-1-.27-3-.81-4-1.11-2.66-.81-5.07-1.71-4-5.16L118.71,29c1.06-3.46,3.55-2.85,6.21-2,1.07.32,3,1,4,1.32Z" />
		<path d="M144.2,39.43l-3.2-2L134.16,48.3l-2.65-1.67,6.84-10.88-3.19-2,1.35-2.13,9,5.68Z" />
		<path d="M66.82,150.7c-.9-.62-2.44-1.7-3.21-2.27-2.23-1.67-4.19-3.32-2-6.21l7-9.33,2.51,1.87-6.46,8.64c-1,1.37-1,1.86.47,3l3.19,2.39Z" />
		<path d="M87.2,146.13l-3.92-1.6c-1.71-.69-2.15-.45-2.78,1.11l-.66,1.62,5.66,2.29-.93,2.3-5.66-2.3-.75,1.85c-.64,1.58-.49,2.06,1.21,2.75l3.93,1.59L82.39,158c-1-.36-3-1.07-3.91-1.46-2.57-1-4.89-2.15-3.53-5.48l3-7.37c1.35-3.33,3.78-2.5,6.35-1.46,1,.42,2.86,1.23,3.82,1.67Z" />
		<path d="M90.21,160.33,96,147.44c.66-1.45,1.55-2,2.73-1.9s1.94.87,2.29,2.38l3.19,13.76-3.3-.31-.81-3.91-5.22-.5-1.51,3.68ZM99.65,155l-1.31-6.38-2.47,6Z" />
		<path d="M109.69,146.17a28.08,28.08,0,0,1,4.81-1.5c5.63-1.24,7.2,1.1,8.32,6.21s.68,7.9-4.95,9.13a29.6,29.6,0,0,1-5,.67Zm3.53,1.5,2.23,10.15a10.34,10.34,0,0,0,1.45-.2c4-.87,3.5-3.13,2.86-6.06s-1.17-5.17-5.13-4.3A11.93,11.93,0,0,0,113.22,147.67Z" />
		<path d="M125.6,141.29l2.82-1.36,6.69,13.85-2.81,1.36Z" />
		<path d="M147.63,140.2l-7.21-8.69,2.17-1.79L151,139.88c1,1.26,1,2.24,0,3s-1.75.81-3.34.13l-9.79-4.16,7.23,8.72L143,149.37l-8.44-10.16c-1-1.26-.95-2.25,0-3s1.79-.79,3.33-.14Z" />
		<path d="M154.78,124.49l2.93-4.63,4.87,3.09c2.4,1.51,1.35,3.49-.27,6-2.73,4.31-5.44,4.12-10.05,1.2s-5.95-5.28-3.23-9.6a24.56,24.56,0,0,1,3-3.91l1.89,1.52a27.28,27.28,0,0,0-2.41,3.3c-2,3.12-.13,4.38,2.43,6s4.49,2.75,6.46-.36c.86-1.36.53-1.74-.2-2.2l-2.05-1.3-1.35,2.13Z" />
	</svg>
)
export default WarrantyIcon
