import React from 'react'
const AustraliaIcon = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 300 300"
		fill="currentColor"
		{...props}
	>
		<title>Icon of the continent of Australia</title>
		<path
			d="M150,279.16a129,129,0,1,0-129.09-129v0A129,129,0,0,0,150,279.16Zm0,20.68A149.92,149.92,0,1,1,300,149.92,149.92,149.92,0,0,1,150,299.84Z"
			fill="#a9bbcd"
		/>
		<path
			d="M164.71,82.79c-1.42,2.95-2.58,5.51-3.93,8a6.86,6.86,0,0,0,.88,8.35,48.51,48.51,0,0,0,14.66,11.95c5.71,2.92,9.48.82,9.74-5.64.21-4.83-.51-9.74-.57-14.6a54.76,54.76,0,0,1,.73-9c.15-1.11,1.11-2.81,1.94-2.94a4.36,4.36,0,0,1,3.46,1.5A57.15,57.15,0,0,1,196.79,88c9.54,16.26,18.79,32.68,28.43,48.8a35.9,35.9,0,0,1,3.21,30.64A88.32,88.32,0,0,1,209,200.71a25.41,25.41,0,0,1-35.49,1.37A155.23,155.23,0,0,1,159,186.81c-9-10.91-19.77-13.73-33.06-9.28a122.48,122.48,0,0,0-25.85,12.92,51.65,51.65,0,0,1-8.48,4.24c-4.37,1.71-6.77,1.06-8.66-3.15-4.57-10.32-8.94-21-13-31.67A113.61,113.61,0,0,1,66,147.13a10.35,10.35,0,0,1,3.23-11q22.3-20.4,44.51-40.89a5.16,5.16,0,0,1,6.83-.75A32.45,32.45,0,0,1,126.17,99a3.14,3.14,0,0,0,4.35.82,3.2,3.2,0,0,0,.82-.82q3.45-3.89,6.52-7.76c2-2.58,3.23-5.32,5.17-7.75a8.76,8.76,0,0,1,4.6-3C153.42,79.43,159,81.06,164.71,82.79Z"
			fill="#5888a7"
		/>
		<path
			d="M192.47,215.65a16.38,16.38,0,0,0,2.95.42,82.92,82.92,0,0,0,9.18-.5c1.93-.25,2.58.44,2.58,2.1A18.69,18.69,0,0,1,202,229.43a2.58,2.58,0,0,1-4.26-.7c-2.18-4.08-4.06-8.32-6.05-12.51Z"
			fill="#5888a7"
		/>
	</svg>
)
export default AustraliaIcon
